
/* mobile */
.ant-modal.my-modal  {
    transition: all 200ms ease;
}
@media only screen and (max-width: 768px) {
    .ant-modal.my-modal  {
        margin: 0;
        background-color: rgba(0, 0, 0, 0.089);
        width: 100%;
        max-width: 100%;
        min-height: 100vh;
        position: initial;
        padding-bottom: 80px;
    }
    .ant-modal-content {
        min-height: 100vh;
    }
}

@layout-body-background: #F9F8F5;@primary-color: #586FE1;@layout-header-background: #255A97;@btn-primary-bg: #586FE1;@btn-default-border: #1C1C1C;@btn-default-color: #1C1C1C;@btn-font-weight: bold;@btn-font-size-lg: 18px;