.my-stake-table table {
  border-collapse: separate !important;
  border-spacing: 0 10px !important;
  margin-top: -10px;
}
.my-stake-table th {
  background-color: white !important;
  padding: 100px;
  font-size: 14px;
  font-weight: bold !important;
  text-align: center !important;
  box-shadow: 0 4px 4px -4px #e7e7e7;
  border-top: 1px solid #cfcfcf;
}
.my-stake-row {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #535353;
  min-height: 200px;
}
.ant-table-tbody .my-stake-row td {
  padding: 10px 0;
  /* display: inline-block; */
}
.ant-table-column-sorters {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.ant-table-filter-column {
}
.nameCell {
  
}
.expand-col {
  width: 10px;
  padding-right: 1px !important;
  margin-left: -10px !important;
}

.expand-col .ant-table-filter-trigger-container {
  padding-right: 15px;
}

.expand-col .ant-table-filter-trigger-container :hover {
  background: none;
}


@layout-body-background: #F9F8F5;@primary-color: #586FE1;@layout-header-background: #255A97;@btn-primary-bg: #586FE1;@btn-default-border: #1C1C1C;@btn-default-color: #1C1C1C;@btn-font-weight: bold;@btn-font-size-lg: 18px;