@font-face {
  font-family: 'Aion Aeonik';
  font-weight: normal;
  src: url('./assets/fonts/AionAeonik-Regular.otf') format('opentype'),
}
@font-face {
  font-family: 'Aion Aeonik Bold';
  font-weight: bold;
  src: url('./assets/fonts/AionAeonik-Bold.otf') format('opentype'), 
}
html, body {
  font-family: 'Aion Aeonik';
  height:100%;
  background-color: #FCFCFC;
}
.App {
}
/* buttons */
.ant-btn-link:hover {
  color: black !important;
}
.my-outline-btn.ant-btn:hover {
  opacity: 0.6;
  border-color: #1c1c1c;
  color: #1c1c1c;
}

/* pagination layout */

.ant-pagination-prev {
  background: #ffffff;
  border-radius: 20px !important;
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.ant-pagination-prev a {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
  border-radius: 20px !important;
}

.ant-pagination-next {
  background: #ffffff;
  border-radius: 20px !important;
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.ant-pagination-next a {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
  border-radius: 20px !important;
}

.ant-pagination-item {
  border: none !important;
  border-radius: 20px !important;
  margin-left: 6px !important;
  margin-right: 6px !important;
}
.ant-pagination-item a {
  border-radius: 20px !important;
  text-decoration: none !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 11px !important;
}

.ant-pagination-item-active {
  background-color: #255a97 !important;
}
.ant-pagination-item-active a {
  color: #fff !important;
}

.mobile-menu {
  display: none;
}
.site-layout.ant-layout {
  min-height: 100vh;
}

.ant-menu-item{
  padding-left: 15px!important;
  margin: 10px 15px 0px!important;
  width: auto!important;
}
.ant-menu-item-selected {
  background-color: #143050!important;
  border-radius: 3px;
}
.ant-slider-handle {
  width: 20px !important;
  height: 20px !important;
  margin-top: -8px !important;
  z-index: 2;
  border-color: #586FE1!important;
}
.ant-collapse-content-box{
  padding-top: 1px!important;
}
.ant-slider-track{
  background-color: #586FE1!important;
  height: 6px !important;
}
.ant-slider-rail{
  height: 6px !important;
}
.ant-tag{
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.02em;
  min-width: 55px;
}
.ant-tag img{
  height: 18px;
  margin-top: -2px;
}
.ant-tag-yellow{
  color: #FFB900 !important;
  border-color:#FFB900 !important;
}

.ant-tabs-tab-btn{
  font-weight: bold!important;
}
.ant-select-selector{
  min-height: 45px!important;
}
.ant-card {
  overflow-x: hidden;
}
.myCollapse .ant-collapse-arrow{
  right: auto!important;
  left: 132px!important;
}
/* mobile */
@media only screen and (max-width: 768px) {
  .ant-layout-sider {
    display: none;
  }
  .ant-layout-header {
    display: none !important;
  }
  .mobile-menu {
    display: inline-block;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 80px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
  }
  .ant-card {
    border-radius: 6px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    border: none !important;
  }
  .ant-card-body{
    border: none !important;
    box-shadow: none !important;
  }
}
