/* tabs */
.my-tabs.ant-tabs > .ant-tabs-nav {
  background: rgba(233, 240, 243, 0.5);
  margin: 0;
}
.my-tabs.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #888888;
}
.my-tabs.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  color: #000000;
}
.my-tabs.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 4px;
}

/* table */

.my-table th {
  background-color: white !important;
  padding: 100px;
  font-size: 14px;
  font-weight: bold !important;
  text-align: center !important;
  box-shadow: 0 4px 4px -4px #e7e7e7;
  border-top: 1px solid #cfcfcf;
}
.my-tx-row {
  text-align: center !important;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: #535353;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .my-tabs.ant-tabs-large > .ant-tabs-nav .ant-tabs-nav-wrap-ping-right {
    min-width: 100%;
  }
}
@layout-body-background: #F9F8F5;@primary-color: #586FE1;@layout-header-background: #255A97;@btn-primary-bg: #586FE1;@btn-default-border: #1C1C1C;@btn-default-color: #1C1C1C;@btn-font-weight: bold;@btn-font-size-lg: 18px;